$( document ).on('turbolinks:load', function(){
  if($('.resend-activation-email').length){
    let $link = $('.resend-activation-email__link')

    $link.on('ajax:success', (ev)=>{
      ev.preventDefault();

      let classToShow = $link.data('classToShow');
      let $toShow = $("."+classToShow+"");
      let classToHide = $link.data('classToHide');
      let $toHide = $("."+classToHide+"");

      $toShow.removeClass(classToShow).addClass(classToHide);
      $toHide.removeClass(classToHide).addClass(classToShow);
    });

  }

});