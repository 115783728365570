const initialize = () => {
    const element = document.querySelector(".benefit-page__unique-credit");
    if (element) {
        const redirectTime = 15000; // 15 segundos para la activación automática
        const interval = 10; // Intervalo de actualización para la barra de progreso

        const updateButtonProgress = ($button, percentage) => {
            $button.css("background", `linear-gradient(to right, #0076DB ${percentage}%, gray ${percentage}%)`);
        };

        const triggerButton = ($button) => {
            $button.trigger("click");
        };

        const resetAndStartButtonProgress = ($button) => {
            let elapsed = 0; // Reinicia el tiempo transcurrido

            const progressInterval = setInterval(() => {
                elapsed += interval;
                const percentage = (elapsed / redirectTime) * 100;
                updateButtonProgress($button, percentage);

                if (elapsed >= redirectTime) {
                    clearInterval(progressInterval);
                    triggerButton($button);
                }
            }, interval);

            $button.data('progressInterval', progressInterval);
        };

        const $buttons = $(".benefit-page__credit-option__simulate-button-link");

        $buttons.each((index, element) => {
            const $button = $(element);
            resetAndStartButtonProgress($button);

            $button.on("click", () => {
                const progressInterval = $button.data('progressInterval');
                clearInterval(progressInterval);
            });
        });

        $('#validationModal').on('hidden.bs.modal', () => {
            $buttons.each((index, element) => {
                const $button = $(element);
                const progressInterval = $button.data('progressInterval');
                clearInterval(progressInterval);
                updateButtonProgress($button, 0);
                resetAndStartButtonProgress($button);
            });
        });
    }
};
$(document).on('turbolinks:load', initialize);
  