
document.addEventListener('turbolinks:load', () => {
    initialize();
});
  
const initialize = () => {
    const container = document.querySelector('#movements-grid-container');
    if (!container) return;
    
    const paymentRequestId = document.querySelector('#payment-request-id')?.getAttribute('data-id');
  
    const setupUpdateNominaButton = () => {
      const updateNominaButton = document.getElementById('update-nomina');
      updateNominaButton?.addEventListener('click', refreshGridAndResetFilter);
    };
  
    const setupFilterFormListener = () => {
      const filterForm = document.querySelector('#status-filter-form');
      filterForm?.addEventListener('change', sendAjaxRequest);
    };
  
    const setupMasterSwitch = () => {
      const masterSwitch = document.querySelector('#master-switch');
      masterSwitch?.addEventListener('change', () => handleMasterSwitchChange(masterSwitch, paymentRequestId));
    };
  
    const handleMasterSwitchChange = (masterSwitch, paymentRequestId) => {
      const newStatus = masterSwitch.checked ? 'payed' : 'pending';
      changeAllStatuses(newStatus, paymentRequestId);
    };
  
    const sendAjaxRequest = function() {
      const formData = new FormData(this);
      const searchParams = new URLSearchParams(formData).toString();
      const currentFilterValue = formData.get('status') || '';
      localStorage.setItem('lastPaymentRequestFilter', currentFilterValue);
  
      fetch(`${this.action}?${searchParams}`, {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.text())
      .then(data => {
        container.innerHTML = data;
        reattachEventListeners();
        updateFilterVisualState();
      })
      .catch(error => console.error('Error:', error));
    };
  
    const refreshGridAndResetFilter = () => {
      localStorage.setItem('lastPaymentRequestFilter', '');
  
      fetch(`/admin/payment_requests/${paymentRequestId}/refresh_grid?status=all`, {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => response.json())
      .then(data => {
        container.innerHTML = data.html;
        reattachEventListeners();
        resetFilterVisualState();
      })
      .catch(error => console.error('Error:', error));
    };
  
    const resetFilterVisualState = () => {
      const filterSelect = document.querySelector('#status-filter-form select[name="status"]');
      const masterSwitch = document.querySelector('#master-switch');
      if (filterSelect) filterSelect.value = '';
      if (masterSwitch) masterSwitch.checked = areAllSwitchesChecked();
    };
  
    const reattachEventListeners = () => {
      setupUpdateNominaButton();
      setupFilterFormListener();
      setupMasterSwitch();
      attachEventListenersToSwitches();
      attachEventListenersToSaveIcons();
    };
  
    const updateFilterVisualState = () => {
      const filterSelect = document.querySelector('#status-filter-form select[name="status"]');
      let filterValue = sessionLastPaymentRequestFilter();
      if (filterSelect) filterSelect.value = filterValue === 'all' || filterValue === '' ? '' : filterValue;
  
      const masterSwitch = document.querySelector('#master-switch');
      if (masterSwitch) masterSwitch.checked = areAllSwitchesChecked();
    };
  
    const attachEventListenersToSwitches = () => {
      document.querySelectorAll('.status-switch').forEach(switchEl => {
        switchEl.addEventListener('change', (event) => {
          const newStatus = event.target.checked ? 'payed' : 'pending';
          updateStatus(event.target, newStatus);
        });
      });
    };
  
    const attachEventListenersToSaveIcons = () => {
      document.querySelectorAll('.save-description-icon').forEach(icon => {
        icon.addEventListener('click', (event) => {
          const detailId = event.target.getAttribute('data-id');
          const comment = document.querySelector(`#comment-${detailId}`).value;
          saveComment(detailId, comment);
        });
      });
    };
  
    const sessionLastPaymentRequestFilter = () => localStorage.getItem('lastPaymentRequestFilter') || '';
  
    const areAllSwitchesChecked = () => {
      const switches = document.querySelectorAll('.status-switch');
      return switches.length > 0 && Array.from(switches).every(switchEl => switchEl.checked);
    };
  
    const updateStatus = (switchEl, status) => {
      const detailId = switchEl.getAttribute('data-id');
      fetch(`/admin/payment_requests/${detailId}/switch_update_status`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status, detail_id: detailId }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) console.log('Status updated successfully');
        else console.error('Error updating status');
      })
      .catch(error => console.error('Error:', error));
    };
  
    const saveComment = (detailId, comment) => {
      fetch(`/admin/payment_requests/${detailId}/save_comment`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comment }),
      })
      .then(response => response.json())
      .then(data => {
        const saveIcon = document.querySelector(`.save-description-icon[data-id="${detailId}"]`);
        const successMessage = document.createElement('span');
        if (data.success) {
          successMessage.classList.add('save-success-message');
          successMessage.textContent = 'Guardado';
        } else {
          successMessage.classList.add('save-error-message');
          successMessage.textContent = 'Error al guardar';
        }
        saveIcon.parentNode.insertBefore(successMessage, saveIcon.nextSibling);
        setTimeout(() => {
          successMessage.remove();
        }, 3000);
      })
      .catch(error => console.error('Error:', error));
    };
  
    const changeAllStatuses = (newStatus, paymentRequestId) => {
      fetch(`/admin/payment_requests/${paymentRequestId}/switch_update_status`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus, master_switch: true }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          document.querySelectorAll('.status-switch').forEach(switchEl => switchEl.checked = newStatus === 'payed');
        } else console.error('Error updating all statuses');
      })
      .catch(error => console.error('Error:', error));
    };
  
    // Inicializar los componentes al cargar
    setupUpdateNominaButton();
    setupFilterFormListener();
    setupMasterSwitch();
    attachEventListenersToSwitches();
    attachEventListenersToSaveIcons();
};
  