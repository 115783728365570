const printAlerts = (payment_status, element, messageResponse = null) => {
  let message = null;
  element.innerHTML = ""
  if (payment_status === "pending") {
    setTimeout(() => {
      getStatus(element)
    }, 10000)
    element.innerHTML += '<div class="alert alert-info" role="alert">\n' +
      'Tu crédito fue aprobado. Recibirás un email con el detalle de la transferencia de dinero.' +
      '</div>'
    return
  }

  if (payment_status === "approved") {
    message = messageResponse ? messageResponse : 'Pago exitoso'
    element.innerHTML += '<div class="alert alert-success" role="alert">\n' +
      message +
      '</div>'
    return
  }

  if (payment_status === "rejected") {
    message = 'La compra no se pudo completar con éxito. Sin embargo, hemos desembolsado la plata en tu cuenta. Ahora tienes la plata de este préstamo disponible en tu cuenta para que finalices la compra.'
    element.innerHTML += '<div class="alert alert-danger" role="alert">\n' +
      message +
      '</div>'
    return
  }

  if (payment_status === "error") {
    message = 'Ha ocurrido un error al obtener los datos'
    element.innerHTML += '<div class="alert alert-danger" role="alert">\n' +
      message +
      '</div>'
    replaceHtmlToError()
    return
  }

  message = 'Ha ocurrido un error al obtener los datos'
  element.innerHTML += '<div class="alert alert-danger" role="alert">\n' +
    message +
    '</div>'
  return

}

const replaceHtmlToError = () => {
  const urlActual = window.location.href;
  const token = urlActual.split('/')[urlActual.split('/').indexOf('creditos_bancarios') + 1];
  $.ajax({
    type: "GET",
    url: `/prestamos_bancarios/${token}/bnpl_error`,
    success: function (data) {
      $('#credits_success_app').html(data)
    },
    error: function (data) {
      console.error('Error al cargar la vista');
    }
  });
}

const getStatus = async (element) => {
  const token = window.location.pathname.split("/")[2]
  const response = await (await fetch(`/creditos_bancarios/${token}/get_payment_status`)).json()
  if (response) {
    printAlerts(response.payment_status, element, response.message)
  } else {
    printAlerts("error", element, response.message)
  }
}

const initialize = () => {
  const element = document.querySelector(".credit-lending__show-status")
  if (element) {
    getStatus(element)
  }
}
document.addEventListener("turbolinks:load", () => {
  initialize()
})