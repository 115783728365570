import $ from "jquery";

$( document ).on('turbolinks:load', function(){
  if ($('#edit_financier').length) {
    let $branch_billing = $('.branch-billing');
    let $finish_branches = $('#confirm_branches_form');
    let $accept_modal = $('#btn_confirm_complete_modal')
    let $completed_branches = $('#completed_branches');
    let $flag_modal = false;

    $finish_branches.on('submit', function(e){
      if(!$flag_modal && $completed_branches.val() === "false"){
        e.preventDefault();
      }
      $flag_modal = false;
    })

    $accept_modal.on('click', function(){
      $flag_modal = true;
      $finish_branches.submit();
    });

    const choseBilling = function($option){
      let $input = $branch_billing.find('.branch-billing__input');
      $input.val($option.data('billingId'));
      let $btn = $branch_billing.find('.branch-billing__btn');
      $btn.text($option.data('billingBusinessName'));
    };

    $branch_billing
      .on('click',".dropdown-item.add-billing", function(e){
        $(this).addClass('clicked');
        e.preventDefault();
      })
      .on('click', ".dropdown-item.billing-created", function(e){
        choseBilling($(this));
        e.preventDefault();
      });
    let created = function(html){
      $('.branch-billing__options').append($(html));
      $('.billing-modal__close-btn').trigger('click');
      choseBilling($('.dropdown-item.billing-created:last-child'));
    };

    let errorCreating = function(event){
      // TODO: mostrar un mensaje de error
      console.log("error", event)
    }

    $('#create_billing_form').on("ajax:success", function(event) {
      let result = event.detail[0];
      let success = result.success;
      if(success){
        created(result.content);
      }else{
        errorCreating(event);
      }
    }).on("ajax:error", function(event) {
      errorCreating(event);
    });
  }
});