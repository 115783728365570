import $ from "jquery";

$( document ).on('turbolinks:load', function(){
  if($("#subscription_reveniu_form").length > 0) {
    $('#reveniu_subscription_provider_id').change(function() {
      $('#reveniu_subscription_billing_id').empty();
      let url = $("#reveniu_subscription_provider_id").data("billingUrl").slice(0,-1) + $(this).val();
      $("#reveniu_subscription_billing_id").empty();
      $.get(url, function(data) {
        $('#reveniu_subscription_billing_id').append("<option selected='selected'>Seleccionar facturación</option>");
        data.options.forEach(function( option ){
          $('#reveniu_subscription_billing_id').append("<option value="+option[0]+">"+option[1]+"</option>");
        });
      });
    });
  }
});