import $ from "jquery";


const addParam = (currentUrl, paramName, paramValue) => {
  if (currentUrl.includes('?')) {
    currentUrl += '&' + paramName + '=' + paramValue;
  } else {
    currentUrl += '?' + paramName + '=' + paramValue;
  }
  return currentUrl
}

const sendRequest = (e)=> {
  let currentUrl = window.location.href;
  let paramName = e.target.name;
  let paramValue = e.target.value;
  var searchParams = new URLSearchParams(new URL(currentUrl).search);

  if (searchParams.has(paramName)) {
    searchParams.delete(paramName);
    searchParams.append(paramName, paramValue)
    currentUrl = currentUrl.split('?')[0] + '?' + searchParams.toString();
  } else {
    currentUrl = addParam(currentUrl, paramName, paramValue)
  }
  window.location = currentUrl;
}

const sendFiltersQuery = () => {
  $("#filterrific_by_country").on('select2:select', (e) => {
    sendRequest(e)
  })

  $("#filterrific_by_source").on('select2:select', (e) => {
    sendRequest(e)
  })
}

$( document ).on('turbolinks:load', function(){
  sendFiltersQuery()
  if ($('#admin_select_not_requested_payment_request').length) {
    let paymentRequestId = $('#payment_request_id')[0].value
    let body = $('#expenses-not-requested-body')

    $('#add-expense-not-requested').on('click', function(e) {
      e.preventDefault();
      let selectedValue = $('#selected-expense-not-requested')[0].value
      let alert = $('#field-alert')
      if(!selectedValue){
        alert.empty();
        alert.removeClass('inactive').addClass('active');
        alert.append('Ingrese un gasto antes de agregar');
        return;
      }

      $.ajax({
        url: `/admin/payment_requests/${paymentRequestId}/add_expense/${selectedValue}`,
        success: function(data){
          if (data.not_found) {
            alert.empty();
            alert.removeClass('inactive').addClass('active');
            alert.append('El gasto seleccionado no se encontró');
          }
          else{
            alert.empty();
            alert.removeClass('active').addClass('inactive');
            body.append(
              `<tr id="manual-expense-line-${data.id}">
                <td class="cell-data"> ${data.id} </td>
                <td class="cell-data"> ${data.liquidator_ref_eid} </td>
                <td class="cell-data"> ${data.liquidator_ref_id} </td>
                <td class="cell-data"> <a href="#" class="remove-expense-not-requested" data-id="${data.id}">Remover</a> </td>
              </tr>`
            )
          }
        },
        error: function(){
          alert.empty();
          alert.removeClass('inactive').addClass('active');
          alert.append('No se ha podido obtener la información del gasto');
        }
      })
    });

    body.on('click', '.remove-expense-not-requested', function(e) {
      e.preventDefault();
      let selectedValue = this.dataset.id
      $.ajax({
        url: `/admin/payment_requests/${paymentRequestId}/remove_expense/${selectedValue}`,
        success: function(data){
          let lineExpense = $(`#manual-expense-line-${data.id}`)[0]
          lineExpense.remove();
        },
        error: function(){
          console.log("No se ha podido obtener la información");
        }
      })
    });
  }
});