import $ from "jquery";
$(document).on("turbolinks:load", function() {
  if($("#payment_url_index").length > 0){
    $('.btnClipboard').on('click', function() {
      navigator.clipboard.writeText($(this).data("url"));
      // Change text and color
      $(this).html("Copiado");
      $(this).css("text-decoration", "none");
      setTimeout(function() {
        $(this).html('');
        $(this).append("<i class=\"fa fa-files-o\"></i>");
        $(this).css("border", "0.5px solid white");
      }.bind(this), 2000);
    });
  }
});


