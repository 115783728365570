$( document ).on('turbolinks:load', function() {
  if ($('#account_bond').length) {
    $('#date_filter').on('change', function(){
      $('#filters_form').submit();
    })

    $('#delete_search').on('click', function(){
      $('#searcher').val('');
      $('#filters_form').submit();
    })

    $('#search').on('click', function(){
      if($('#searcher').val().length){
        $('#filters_form').submit();
      }
    })

    $('#search_container').keypress(function(event){
      if(event.keyCode == 13){
        event.preventDefault();
        $("#search").click();
      }
    })
  }
})