document.addEventListener('turbolinks:load', () => {

  if ($('#account_movement').length) {
    $('#date_filter').on('change', function(){
      $('#filters_form').submit();
    })
  }

  if($('.admin-account-payments-container').length){
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
        mainContent.classList.add('admin-account-payments-main');
    }
  }
});