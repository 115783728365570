/*
Este js se encarga del siguiente comportamiento:
si hay un formulario con la clase .form_with_required, el botón submit estará desactivado
hasta que todos los campos (input, select, textarea con required tengan un valor
 */
const FORM_SELECTOR = '.form_with_required'
const REQUIRED_FIELDS_SELECTOR = 'input[required], select[required], textarea[required]';
const BTN_SELECTOR = 'input[type=submit], button[type=submit]';
const LISTENING_EVENTS = 'keyup blur click';

$( document ).on('turbolinks:load', function(){
  let $form_with_required = $(FORM_SELECTOR);
  if ($form_with_required.length) {
    const validateForm = () => {
      let $requiredFields = $form_with_required.find(REQUIRED_FIELDS_SELECTOR);
      let $submitBtns = $(BTN_SELECTOR);

      let allHasValue = true;
      $requiredFields.each((_index,field) => {
        if($(field).prop('type') == 'checkbox') {
          allHasValue = allHasValue && $(field).prop('checked');
        }
        else {
          allHasValue = allHasValue && $(field).val();
        }
      });

      if (allHasValue) {
        $submitBtns.prop("disabled", false );
      } else {
        $submitBtns.prop("disabled", true );
      }
    }
    $form_with_required.on(LISTENING_EVENTS, REQUIRED_FIELDS_SELECTOR, validateForm);
    validateForm();
  }
});