$( document ).on('turbolinks:load', function() {
    if($('.wizard-provider-form').length) {
        const requiredLabels = $(".required-field");

        requiredLabels.each( function( index, element ){
            // take the input with same id than the label
            $(`#${element.getAttribute("for")}`).on("focusout", function () {
                if ($(this).val().trim() === "") {
                    $(this).css("border-color", "red");
                } else {
                    $(this).css("border-color", "");
                }
            });
        });
    }
});