const initialize = () => {
  const element = document.querySelector(".jwt-token-checker");
  if (element) {
    const button = document.querySelector("#generate-jwt-button");

    button.addEventListener("click", (event) => {
      fetch('/admin/app_status/regenerate_jwt', {
        method: 'GET'
      })
        .then(response => response.json())
        .then(data => {
          element.innerText = data.data;
        })
    })
  }
};
$(document).on('turbolinks:load', initialize);
  