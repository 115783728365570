$( document ).on('turbolinks:load', function() {
  if($('.wizard-confirm-payment').length > 0) {
    let $button = $('.wizard-confirm-payment--continue');
    let checkPayment = () => {
      $.ajax({
        type: "GET",
        url: '/wizard/confirm_payment/check_payment',
        success: function (data) {
          if (data) {
            $button.removeClass("disabled");
            successConnection()
          }
        },
        error: function (data) {
          failedConnection();
        }
      });
    }

    let successConnection = () => {
      clearInterval(intervalId);
    }

    let failedConnection = () => {
      clearInterval(intervalId);
    }

    let intervalId = window.setInterval(function () {
      checkPayment();
    }, 5000);

    $button.closest('form').on('submit', function () {
      return !$button.hasClass('disabled')
    });
  }
});