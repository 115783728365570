import $ from 'jquery'

$( document ).on('turbolinks:load', function(){
  if ($('#edit_billing').length) {

    let clearModal = function () {
      let modal = $('#bank_account_modal'),
        inputs = modal.find('input.form-control'),
        select = modal.find('select');
      inputs.val('');
      select.val(select.find('option:first').val());
    }

    let showMessages = function () {
      $('.bank-account-messages').removeClass('d-none');
    }

    let hideMessages = function () {
      $('.bank-account-messages').addClass('d-none');
    }

    let checkSelected = function () {
      let select = $('#billing_bank_account_id');
      if (select.val() === "") {
        showMessages();
      }else {
        hideMessages();
      }
    }

    $('#bank_account_modal').find('.close-modal').on('click', function (e) {
      clearModal();
    });

    $(".dropdown-item.add-bank-account").on('click', function(e){
      $(this).addClass('clicked');
      e.preventDefault();
    });

    $("#create_bank_account_form").on("ajax:success", function(event) {
      let detail = event.detail[0],
        success = detail.success;
      if(success){
        let content = detail.content,
          showInfo = detail.info,
          bankAccount = detail.bank_account,
          dropdown = $('#bank-account-dropdown-menu'),
          dropdownMenuBtn = $('#dropdownMenuButton'),
          bankAccountClicked = $(".dropdown-item.add-bank-account.clicked"),
          modal = $('#bank_account_modal'),
          closeBtn = modal.find('.close'),
          bankAccountInput = $('#billing_bank_account_id');
        dropdown.append(content);
        dropdownMenuBtn.html(showInfo);
        bankAccountClicked.removeClass('clicked');
        bankAccountInput.val(bankAccount.id);
        checkSelected();
        closeBtn.click();
      }else{
        event.preventDefault();
      }

    }).on("ajax:error", function(event) {
      console.log('error billing', event);
      event.preventDefault();
    });

    $("#create_dm_provider_form").on("ajax:success", function(event) {
      let detail = event.detail[0],
      success = detail.success;
      if(success){
        let content = detail.content,
          showInfo = detail.info,
          bankAccount = detail.bank_account,
          dropdown = $('#dm-provider-dropdown-menu'),
          dropdownMenuBtn = $('#dropdownMenuButton-dm-provider'),
          bankAccountClicked = $(".dropdown-item.add-dm-provider.clicked"),
          modal = $('#dm-provider_modal'),
          closeBtn = modal.find('.close');
        dropdown.append(content);
        dropdownMenuBtn.html(showInfo);
        bankAccountClicked.removeClass('clicked');
        checkSelected();
        closeBtn.click();
      }else{
        event.preventDefault();
      }
    }).on("ajax:error", function(event) {
      event.preventDefault();
    });


    $('#container_billing_form').on('click','.dropdown-item.bank-account-created', function(e){
      let _this = $(this),
        bankAccountInput = $('#billing_bank_account_id'),
        dropdownMenuBtn = $('#dropdownMenuButton'),
        id = _this.data('bank-account-id'),
        info = _this.data('bank-account-info');
      bankAccountInput.val(id);
      dropdownMenuBtn.html(info);
      checkSelected();
      e.preventDefault();
    });

    $('#container_billing_form').on('click', '.dropdown-item.add-bank-account', function(e){
      $(this).addClass('clicked');
      e.preventDefault();
    });


  }
});