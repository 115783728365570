const initialize = () => {
  const element = document.querySelector(".credit-lending__error-message");
  if (element) {
    const token = window.location.pathname.split("/")[2]
    setTimeout(() => {
      window.location = `/beneficios/${token}/`
    }, 3000)
  }
 
}

document.addEventListener("turbolinks:load", () => {
  initialize();
})
