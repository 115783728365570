const printAlerts = (payment_status, element, messageResponse = null, inter) => {
  let message = null;
  element.innerHTML = ""
  if (payment_status === "pending") {
    element.innerHTML += '<div class="alert alert-warning" role="alert">\n' +
      'Pago pendiente' +
      '</div>'
    setTimeout(getStatus, 5000, element);
    return
  } else if (payment_status === "approved") {
    message = messageResponse ? messageResponse : 'Pago exitoso'
    element.innerHTML += '<div class="alert alert-success" role="alert">\n' +
      message +
      '</div>'
    return
  } else if (payment_status === "transfed") {
    message = 'La compra no se pudo completar con éxito. Sin embargo, hemos desembolsado la plata en tu cuenta. Ahora tienes la plata de este préstamo disponible en tu cuenta para que finalices la compra.'
    element.innerHTML += '<div class="alert alert-danger" role="alert">\n' +
      message +
      '</div>'
    return
  } else if (payment_status === "rejected") {

    let urlActual = window.location.href;
    let token = urlActual.split('/')[urlActual.split('/').indexOf('prestamos_bancarios') + 1];
    $.ajax({
      type: "GET",
      url: '/prestamos_bancarios/' + token + '/bnpl_error',
      success: function(data) {
        $('#bnpl_success_container').html(data)
      },
      error: function(data)
      {
        console.error('Error al cargar la vista');
      }
    });
    return
  }

  message = 'Ha ocurrido un error al obtener los datos'
  element.innerHTML += '<div class="alert alert-danger" role="alert">\n' +
    message +
    '</div>'
  return

}

const stamp_obligation = (n_obligacion) => {
  let text_obligacion = "número de obligación";
  // busco el texto en todos los <p>
  let elementosP = document.querySelectorAll('div > p');

  elementosP.forEach(function(elementoP) {
    if (elementoP.textContent.trim().toLowerCase() === text_obligacion) {
      let spanElement = elementoP.parentElement.nextElementSibling.querySelector('span');

      spanElement.textContent = n_obligacion;
    }
  });
}

const getStatus = async (element) => {
  const token = window.location.pathname.split("/")[2]
  const response = await (await fetch(`/prestamos_bancarios/${token}/get_payment_status`)).json()
  if (response) {
    stamp_obligation(response.n_obligacion)
    printAlerts(response.payment_status, element, response.message)
  } else {
    printAlerts("error", element, response.message)
  }

}

const initialize = () => {
  const element = document.querySelector(".bnpl-lending__show-status")
  if (element) {
    getStatus(element)
  }
}
document.addEventListener("turbolinks:load", () => {
  initialize()
})