$( document ).on('turbolinks:load', () => {

  const SVG = "image/svg+xml";
  const ALLOWED_TYPES = [
      SVG,
      "image/png",
      "image/jpeg"
  ];
  const LIMIT_SIZE = 21000;
  const MAX_WIDTH = 145;
  const MAX_HEIGHT = 85;

  let initField = function($field){
    let $input = $field.find('input[type=file]');
    let $target = $field.find('.inline-logo-field__data-input');

    let content_type_id = $field.data('contentTypeId');
    let filename_id = $field.data('filenameId');
    let $content_type = null;
    let $filename = null;
    if (content_type_id) {
      $content_type = $('#'+content_type_id);
      if ($content_type.length == 0) {
        $content_type = null;
      }
    }
    if (filename_id) {
      $filename = $('#'+filename_id);
      if ($filename.length == 0) {
        $filename = null;
      }
    }

    const clearRules = function() {
      $field.find('.rule-icon').addClass('d-none');
    }

    const markRule = function(rule, result){
      let $icon;
      if (result) {
        $icon = $field.find(".inline-logo-field__rule--"+rule+' .rule-icon--ok');
      } else {
        $icon = $field.find(".inline-logo-field__rule--"+rule+' .rule-icon--error');
      }
      $icon.removeClass('d-none');
    };

    const showPreview = function(image) {
      let $container = $field.find('.inline-logo-field__preview');
      $container.empty();
      $container.append(image);
    }

    $field.on('click','.inline-logo-field__input-btn',function(ev){
      ev.preventDefault();
      $input.trigger('click');
    });

    $input.on('change',function(){
      let file = this.files[0];
      if (!file){
        return;
      }
      clearRules();
      if (! ALLOWED_TYPES.includes(file.type)) {
        markRule('format',false);
        return; // abortamos
      }
      markRule('format',true);
      if (file.size > LIMIT_SIZE) {
        markRule('size',false);
        return; // abortamos
      }
      markRule('size',true);
      let fileReader = new FileReader;
      fileReader.onload = function(){
        let image = new Image;
        image.onload = function(){
          if (file.type == SVG) {
            // en los SVG en realidad no nos importa el tamaño
            markRule('width',true);
            markRule('height',true);

          } else {
            if (image.width > MAX_WIDTH) {
              markRule('width',false);
              return;
            }
            markRule('width',true);

            if (image.height > MAX_HEIGHT) {
              markRule('height',false);
              return;
            }
            markRule('height',true);
          }
          // estamos OK
          showPreview(image);
          $target.val(image.src);
          if ($content_type) {
            $content_type.val(file.type);
          }
          if ($filename) {
            $filename.val(file.name);
          }
        }
        image.src = fileReader.result;
      }
      fileReader.readAsDataURL(file);
    });

  }

  $('.field-unit__field--inline-logo').each(function(){
    initField($(this));
  });
});