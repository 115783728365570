const startAutoSendToggles = () => {

  document.querySelectorAll('.auto-send-toggle').forEach(switchEl => {
    switchEl.addEventListener('change', (event) => {
      const form = event.target.closest("form")
      if (form) {
        form.submit();
      }
    });
  });
}

export default startAutoSendToggles