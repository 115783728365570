import $ from "jquery";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";

$( document ).on('turbolinks:load', function(){
  if($(".admin-search").length > 0) {
    let $search_input = $(".admin-search__input");
    let $search_button = $(".admin-search__button");
    let $body_reload = $(".admin-search__reload");
    let $page = $(".admin-search__page");
    let $filter = $(".admin-search__filter");
    let $submit_button = $(".admin-search__submit");
    let $loading_section = $(".admin-search__loading-section")

    let searchAjaxRequest = (url) => {
      if (url === undefined || url === "") {
        url = $(".admin-search__form").attr("action");
      }

      $.ajax({
        url: url,
        dataType: 'JSON',
        data: $(".admin-search__form").serialize(),
        type: 'POST',
        beforeSend: function () {
          $loading_section.addClass("admin-search__loading-section-visible")
          $loading_section.show();
        },
        complete: function () {
          $loading_section.removeClass("admin-search__loading-section-visible")
          $loading_section.hide();
        },
        success: function (result) {
          successResponse(result)
        },
        error: function (xhr) {
        }
      });
    }

    const successResponse = (result) => {
      $body_reload.html(result["attachmentPartial"]);
      findAndClickPagination()
      reBindEvTypeSelector()
    }

    $search_button.on("click", function (ev) {
      $page.val(1);
      searchAjaxRequest();
    });

    $search_input.on("keypress", function(ev) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        ev.preventDefault();
        $search_button.trigger('click');
      }
    });

    // Soporte para paginación con kaminari
    const findAndClickPagination = () => {
      $(".pagination").find("a").on('click', function (ev) {
        ev.preventDefault();
        let targetPage = 1;
        let url = ev.target.href;
        if (url === undefined) {
          return;
        }
        // Si tengo el param page en la url, entonces filtro
        if (url.match("page=") !== null) {
          targetPage = url.split("=")[1].split("&")[0];
        }
        $page.val(targetPage);
        searchAjaxRequest(url);
      });
    }

    // Conectar selector de tipo de evaluación con el form de search
    const reBindEvTypeSelector = () => {
      const evTypeSelector = document.querySelector("#select_by_ev_type");
      if (evTypeSelector) {
        evTypeSelector.addEventListener("change", (event) => {
          document.querySelector("#filter_ev_type").value = event.target.value;
          searchAjaxRequest();
        });
      }
    }

    findAndClickPagination()
    reBindEvTypeSelector()

  }
  
  if($('.credit-financing-filters')){
    const $loader = $('.admin-search__loading-section');

    $('.credit-financing-filters__country select, .credit-financing-filters__offers select').on('change', () => {
      $loader.addClass("admin-search__loading-section-visible");
    });

    flatpickr("#date-range", {
      mode: "range",
      dateFormat: "Y-m-d",
      "locale": Spanish,
      onClose: (selectedDates, dateStr, instance) => {
        document.getElementById("date-range-form").submit();
        $loader.addClass("admin-search__loading-section-visible")
      },
    });
  }
});