class FormValidatorRules {

  correctFormatRule(message = null) {
    return {
      rule: 'customRegexp',
      errorMessage: message || 'Ingresa un válor válido',
      value: /^(?!.* {2,}).*$/,
    }
  }

  requiredRule(message = null) {
    return {
      rule: 'required',
      errorMessage: message || 'Este campo es requerido.',
    }
  }

  emailRule(message = null) {
    return {
      rule: 'email',
      errorMessage: message || 'Ingresa un email válido.',
    }
  }

  lengthRule(minLength = 1, maxLength = null, message = null) {

    const minLengthRule = {
      rule: 'minLength',
      value: minLength,
      errorMessage: message || 'Ingresa un valor válido.',
    }

    const response = [
      minLengthRule,
    ]

    if (maxLength) {
      response.push({
        rule: 'maxLength',
        value: maxLength,
        errorMessage: `No puedes ingresar más de ${maxLength} caracteres.`,
      })
    }

    return response

  }

  numberRule = (message) => {
    return {
      rule: 'number',
      errorMessage: message || 'Ingresa un valor númerico.',
    }
  }

}

export default FormValidatorRules;
