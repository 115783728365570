import FormValidatorRules from "../../utilities/formValidatorRules";

const formValidatorRules = new FormValidatorRules();

const simpleRules = [
  formValidatorRules.requiredRule(),
  formValidatorRules.correctFormatRule(),
  ...formValidatorRules.lengthRule(2, 250),
]

const defaultFields = {
  // Valores que siempre se muestran
  '#financier_billing_attributes_business_name': [...simpleRules],
  '#financier_billing_attributes_business_id_type': [
    formValidatorRules.requiredRule()
  ],
  '#financier_billing_attributes_business_id': [...simpleRules],
  '#financier_billing_attributes_order': [...simpleRules],
  '#financier_billing_attributes_address': [...simpleRules],
  '#financier_billing_attributes_number_address': [...simpleRules],
  '#financier_billing_attributes_email': [
    formValidatorRules.emailRule(),
    ...simpleRules
  ]
}

const colombianFields = {
  '#financier_billing_attributes_commercial_business': [...simpleRules],
}

const chileanFields = {
  '#financier_billing_attributes_commune_id': [
    formValidatorRules.requiredRule()
  ],
}

const mexicanFields = {
  '#financier_billing_attributes_city': [...simpleRules],
  '#financier_billing_attributes_postal_code': [
    ...simpleRules,
    formValidatorRules.numberRule(),
  ],
  '#financier_billing_attributes_district': [...simpleRules],
  '#financier_billing_attributes_tax_regime_option_id': [
    formValidatorRules.requiredRule()
  ],
  '#financier_billing_attributes_cfdi_option_id': [
    formValidatorRules.requiredRule()
  ]
}

const allFields = {
  ...mexicanFields,
  ...colombianFields,
  ...chileanFields
}

export {defaultFields, mexicanFields, allFields, colombianFields, chileanFields}