/*
Este js se encarga del siguiente comportamiento:
si hay un div con clase .showable-password, se espera que dentro haya los siguientes 2 elementos:
- un link (<a>) con clase .showable-password__show-btn, que al presionar muestre la contraseña
- un input de tipo password con clase .showable-password__input, que es el que mostrará la contraseña
Adicionalmente, podría estar dentro de un formulario, al que se escuchará su evento submit

El input se volverá automáticamente un input tipo password si el campo pierde el foco,
si se intenta enviar el formulario o tras un rato sin foco
 */

const COMPONENT_SELECTOR= '.showable-password';
const BTN_SELECTOR= '.showable-password__show-btn';
const INPUT_SELECTOR= '.showable-password__input';
const WAIT_TIME_MS= 5001;

$( document ).on('turbolinks:load', () => {
  let $component = $(COMPONENT_SELECTOR);
  if ($component.length) {
    let $btn = $component.find(BTN_SELECTOR);
    let $input = $component.find(INPUT_SELECTOR);
    let $form = $component.closest('form');


    let hidePassword = () => {
      $input.attr('type','password');
    };
    let hidePasswordOnlyIfBlur = () => {
      if (!$input.is(':focus')) {
        hidePassword();
      }
    };
    let showPassword = (ev) => {
      ev.preventDefault();
      $input.attr('type','text');
      window.setTimeout(hidePasswordOnlyIfBlur,WAIT_TIME_MS);
    };

    $btn.on('click',showPassword);
    $input.on('blur',hidePassword);

    if ($form.length) {
      $form.on('submit',hidePassword);
    }
  }
});