import $ from "jquery";

$( document ).on('turbolinks:load', function(){
  // use of select2 on forms
  $('.select2').select2({
    width: "100%",
    height: "500px"
  });
  $('.select2-modal').select2({
    width: "100%",
    height: "500px",
    dropdownParent: ($('#billingModal.show').length ? $('#billingModal') : "") || ($("#bank_account_modal.show").length ? $("#bank_account_modal") : "")
  });
  // por compatibilidad mantengo la siguiente clase, pero prontó se quitará
  $('.js-example-basic-single').select2({
    width: "100%",
    height: "500px"
  });
});