import $ from 'jquery'

$(document).on('turbolinks:load', function() {

  if ($(".benefit-page").length) {

    let initializeValidation = () => {
      let initValidationUrl = $modal.data("initializeValidationUrl") + "?credit_option_id=" + $modal.data("chosenOptionId");
      $.ajax({
        type: "POST",
        url: initValidationUrl,
        data: "",
        success: function(response) {
          if (response) {
            if (response.result === "success") {
              $modal.data("resultUrl", response.data.url_result);
              $modal.data("emailUrl", response.data.url_email);
              $(".validation-modal__description-desktop").html(
                "Escanea el código para validar tu identidad." +
                "<br><br> " + response.data.qr_code_svg + ""
              );
              $(".validation-modal__description-mobile").html(
                "Haz clic en el botón para validar tu identidad." +
                "<br><br> <a href='" + response.data.url_email + "' target='_blank' class='btn btn-primary btn-lg validation-modal__button'>Validarme</a>"
              );
              $(".validation-modal__hint").html(
                "*También puedes validarte haciendo <a class='' href='" + response.data.url_email + "' target='_blank' class=''>clic aquí</a>"
              );
              $modal.addClass("validation-modal__active");
              setTimeout(() => { checkStatusValidation(); }, 1000)
            }
          }
        },
        error: function(response) {
          validationFailed();
        }
      });
    }

    let checkStatusValidation = () => {
      if ($(".validation-modal__active").length) {
        $.ajax({
          type: "POST",
          url: $modal.data("validationStatusUrl"),
          data: { resultUrl: $modal.data("resultUrl") },
          success: function(response) {
            if (response) {
              if (response.result === "success") {
                let $form = $("form." + $modal.data("chosenOption"));
                let finalUrl = $form.attr("action") + "?gerty_id_token=" + response.token;
                let success_message = "La validación fue exitosa. Ahora te redireccionaremos al sitio del financiador.";
                $(".validation-modal__description-desktop").html(success_message);
                $(".validation-modal__description-mobile").html(success_message);

                $(".validation-modal__icon").addClass("validation-modal__icon--success");
                $(".validation-modal__icon").html(
                  "<i class='fa fa-check-circle-o' aria-hidden='true'></i>"
                );
                $(".validation-modal__hint").html("");
                $(".validation-modal__animation").hide();

                $form.attr("action", finalUrl);
                $form.submit();
              } else {
                setTimeout(() => { checkStatusValidation(); }, 1000)
              }
            }
          },
          error: function(response) {
            validationFailed();
          }
        });
      }
    }

    let validationFailed = () => {
      let failure_message = "La validación falló. Por favor, intente nuevamente.";
      $(".validation-modal__description-desktop").html(failure_message);
      $(".validation-modal__description-mobile").html(failure_message);
      $(".validation-modal__hint").html("");
      $(".validation-modal__animation").hide();
      $(".validation-modal__icon").addClass("validation-modal__icon--failed");
      $(".validation-modal__icon").html(
        "<i class='fa fa-times-circle-o' aria-hidden='true'></i>"
      );
      $modal.data("chosenOption", "");
      $modal.data("chosenOptionId", "");
    }

    let $modal = $(".validation-modal");

    $(".benefit-credit-option__modal").on("click", function(ev) {
      $modal.data("chosenOption", $(this).data("chosenOption"));
      $modal.data("chosenOptionId", $(this).data("chosenOptionId"));
    });

    $modal.on("show.bs.modal", function(ev) {
      let connection_message = "Conectando con Gerty ID ...";
      $(".validation-modal__description-desktop").html(connection_message);
      $(".validation-modal__description-mobile").html(connection_message);
      $(".validation-modal__animation").show();
      $(".validation-modal__hint").html("");
      $(".validation-modal__icon").removeClass("validation-modal__icon--failed");
      $(".validation-modal__icon").removeClass("validation-modal__icon--success");
      $(".validation-modal__icon").html("");
      initializeValidation(ev);
    });

    $modal.on("hide.bs.modal", function(ev) {
      if (!confirm('Si cierras esta ventana se cancelará la validación. ¿Deseas continuar?')) {
        ev.preventDefault()
        return
      }
    });

    $modal.on("hidden.bs.modal", function(ev) {
      $modal.removeClass("validation-modal__active");
      $modal.data("chosenOption", "");
      $modal.data("chosenOptionId", "");
    });

    const $confirmationModal = $('#confirmationModal'); // Modal para manejar errores
    let button = document.querySelector('.benefit-page__credit-option__banco-bogota-button-link');

    // Función para mostrar el modal en caso de error
    const showErrorModal = (message) => {
      $confirmationModal.find('.modal-body').html(message);
      $confirmationModal.find('.modal-title').text('Error');
      
      // Mostrar el botón de cerrar
      $confirmationModal.find('.modal-footer').html(`
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      `);

      $confirmationModal.modal('show');
    };

    if (button) {
      button.addEventListener('click', function(event) {
        event.preventDefault(); // Evitamos el comportamiento por defecto del enlace

        // Enviar la solicitud usando AJAX manualmente
        const url = button.getAttribute('href'); // Obtener la URL desde el atributo del botón
        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          beforeSend: () => {
            button.innerText = 'Enviando...';
            button.classList.add('disabled-state');
          },
          success: (data) => {
            let customer_url = data.customer_url;

            // Verificar si la URL tiene protocolo; si no, agregar "https://"
            if (customer_url && !/^https?:\/\//i.test(customer_url)) {
              customer_url = 'https://' + customer_url;
            }

            if (data.status === 'success' && customer_url) {
              // Cambiar el texto del botón a "Ir al crédito"
              button.textContent = 'Enviar solicitud';
              button.classList.remove('disabled-state');
              window.open(customer_url, '_blank');
            } else {
              button.innerText = 'Enviar solicitud';
              button.classList.remove('disabled-state');
              showErrorModal(data.message); // Mostrar modal de error
            }
          },
          error: () => {
            button.innerText = 'Enviar solicitud';
            button.classList.remove('disabled-state');
            showErrorModal('Error, no se pudo enviar la solicitud.'); // Mostrar modal de error
          }
        });
      });
    }
  }
});
