/*
Este js se encarga del siguiente comportamiento:
Copia informacion de un elemento HTML al portapapeles
 */

$( document ).on('turbolinks:load', function(){
  if($('.copiable-clipboard').length){

    const copyToClipboard = (copyText) => {

      // Create a "hidden" input
      var aux = document.createElement("input");
      // Append it to the body
      document.body.appendChild(aux);
      // Assign it the value of the specified element
      aux.setAttribute("value", copyText.replace(/\s/g, ''));

      // Highlight its content
      aux.select();
      // Copy the highlighted text
      document.execCommand("copy");

      // Remove it from the body
      document.body.removeChild(aux);
    }
  
    $('.copiable-clipboard').on("click", (e) => {
      copyText = e.target.parentElement.dataset.copiableInfo;
      copyToClipboard(copyText);
    });
  }

  //basta con tener en el tag que se clickea, la clase 'copy-text-icon' y un 'data-copy' con lo que se quiere copiar
  if ($('.copy-text-clipboard').length) {
    const copyToClipboard = () => {
      $('.copy-text-clipboard').on('click', function() {
        const textToCopy = $(this).data('copy');
        // Usar la API del portapapeles
        navigator.clipboard.writeText(textToCopy).then(() => {
          // Crear tooltip
          const tooltip = $('<div class="copy-tooltip">Texto copiado</div>');
          $(this).after(tooltip);
          tooltip.fadeIn();
          // Eliminar tooltip después de 2 segundos
          setTimeout(() => {
            tooltip.fadeOut(() => {
              tooltip.remove();
            });
          }, 2000);
        }).catch(err => {
          console.error('Error al copiar el texto: ', err);
        });
      });
    };

    copyToClipboard();
  }
})