const setInputs = (element) => {
  if (element) {
    const idHtml = element.dataset.id
    const last4 = element.dataset.last4
    $("#payment_submit_id_account_selected").val(idHtml)
    $("#payment_submit_last_4").val(last4)
  }
}

const selectPrincipalAccount = () => {
  const element = document.querySelector("#selectPrincipalAccount");
  setInputs(element)
}
const initialize = () => {
  selectPrincipalAccount()
  $("#selectPrincipalAccount").on("click", () => {
    $("#modalSelectAccount").modal('show')
  })
  $(".credit-lending__select-account").on("click", (e) => {
    if (e.currentTarget.id !== "selectPrincipalAccount") {
      $(`[id^="credit-lending__select-account-number-"]`).addClass("credit-lending__select-account-number-container");

      if (e.currentTarget.id) {
        const element = e.currentTarget
        setInputs(element)
        const id = element.id.split("_")[2]
        $('input[name="selected_account_id"]').val(id)
        $('input[name="account_number"]').val(element.dataset.last4)
        $('input[name="account_type"]').val(element.dataset.type)
        $("#account-title").text(element.dataset.name)
        $("#account-last4").text(`•••• •••• ${element.dataset.last4}`)
        $(`#credit-lending__select-account-number-${id}`).removeClass("credit-lending__select-account-number-container")
        $(".credit-lending__select-account-selected").removeClass("credit-lending__select-account-selected")
        $(e.currentTarget).addClass("credit-lending__select-account-selected")
      }
    }
  })
  $("#confirmAccount").on("click", () => {
    $("#modalSelectAccount").modal('hide')
  })
}

document.addEventListener("turbolinks:load", () => {
  initialize();
})
