import $ from "jquery";

$(document).on('turbolinks:load', () => {
    const form = $(".admin-form-country-config");

    if (form.length > 0) {
        // Inicializar select2
        $('.select2').select2();

        let emailsToRemoveCredit = [];
        let emailsToRemoveInsurance = [];

        // Lógica para agregar correos dinámicos de créditos
        $('#add-credit-email').on('click', function() {
            $('#credit-emails-container').append(`
                <div class="alert-email-field" style="display:flex; justify-content:center; align-items:center; gap:1rem;">
                    <input type="text" name="country_config[credit_alert_emails][]" class="form-control mb-2" placeholder="Agregar correo">
                    <i class="fa fa-times remove-email" style="font-size: 1.2rem; color: red; cursor: pointer;"></i>
                </div>
            `);
        });

        // Lógica para agregar correos dinámicos de seguros
        $('#add-insurance-email').on('click', function() {
            $('#insurance-emails-container').append(`
                <div class="alert-email-field" style="display:flex; justify-content:center; align-items:center; gap:1rem;">
                    <input type="text" name="country_config[insurance_alert_emails][]" class="form-control mb-2" placeholder="Agregar correo">
                    <i class="fa fa-times remove-email" style="font-size: 1.2rem; color: red; cursor: pointer;"></i>
                </div>
            `);
        });

        // Lógica para eliminar correos y agregarlos a la lista de eliminación
        $(document).on('click', '.remove-email', function() {
            const emailField = $(this).closest('.alert-email-field');
            const emailValue = emailField.find('input').val();
            
            if (emailField.closest('#credit-emails-container').length > 0) {
                emailsToRemoveCredit.push(emailValue); 
            } else if (emailField.closest('#insurance-emails-container').length > 0) {
                emailsToRemoveInsurance.push(emailValue); 
            }

            // Remover el campo de correo visualmente
            emailField.remove();
            
            // Si se elimina el último correo, agregar un campo oculto para enviar un array vacío
            if ($('#credit-emails-container .alert-email-field').length === 0) {
                form.append('<input type="hidden" name="country_config[credit_alert_emails][]" value="">');
            }
            if ($('#insurance-emails-container .alert-email-field').length === 0) {
                form.append('<input type="hidden" name="country_config[insurance_alert_emails][]" value="">');
            }
        });

        // Antes de enviar el formulario, agrega los correos a eliminar en campos ocultos
        form.on('submit', function() {
            emailsToRemoveCredit.forEach(email => {
                form.append(`<input type="hidden" name="country_config[credit_alert_emails_to_remove][]" value="${email}">`);
            });

            emailsToRemoveInsurance.forEach(email => {
                form.append(`<input type="hidden" name="country_config[insurance_alert_emails_to_remove][]" value="${email}">`);
            });
        });

        // Mapeo dinámico de país e ISO
        const countrySelect = document.getElementById("country-select");
        const isoField = document.getElementById("iso-field");

        const countryIsoMapping = form.data('country-iso-mapping');
        if (countryIsoMapping && countryIsoMapping.length > 0) {
            const countryIsoMap = Object.fromEntries(countryIsoMapping);

            function updateIsoField() {
                const selectedCountry = countrySelect.value;
                const selectedIso = countryIsoMap[selectedCountry] || "";
                isoField.value = selectedIso;
            }

            updateIsoField();
            $('#country-select').on('change', function() {
                updateIsoField();
            });
        } else {
            console.error("El mapeo de país e ISO está vacío.");
        }
    }

    const show = $(".admin-show-country-config");
    
    if (show.length > 0) {
        // Evento para cambiar las pestañas
        $('.tab-link').on('click', (e) => {
            const tab = $(e.currentTarget).data('tab');
            const countryConfigId = $('#alerts-container').data('country-config-id');
    
            $('.tab-link').removeClass('active');
            $(e.currentTarget).addClass('active');
    
            $.ajax({
                url: `/admin/country_configs/${countryConfigId}/load_alerts`,
                data: { type: tab.split('-')[0] },
                success: (response) => {
                    $('#alerts-container').html(response);
                    rebindSwitchEvents();
                },
                error: () => {
                    alert('Hubo un error al cargar las alertas.');
                }
            });
        });
    
        // Función para reasignar los eventos a los switches después de cargar alertas
        const rebindSwitchEvents = () => {
            $(document).off('change', '.resolve-switch');
            $(document).on('change', '.resolve-switch', (e) => {
                const alertId = $(e.currentTarget).data('alert-id');
                const isResolved = $(e.currentTarget).is(':checked');
                const countryConfigId = $('#alerts-container').data('country-config-id');
    
                $.ajax({
                    url: `/admin/country_configs/${countryConfigId}/toggle_resolved`,
                    method: 'PATCH',
                    data: { alert_id: alertId, is_resolved: isResolved },
                    success: (response) => {
                        const row = $(`#alert_${alertId}_resolved`).closest('tr');
                        const resolvedAtCell = row.find('.resolved-at');
    
                        if (response.is_resolved) {
                            const formattedDate = new Date().toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
                            resolvedAtCell.text(formattedDate);
                        } else {
                            resolvedAtCell.text('');
                        }
                    },
                    error: () => {
                        alert('Hubo un error al actualizar el estado.');
                    }
                });
            });
        };
        rebindSwitchEvents(); // Inicializa los eventos cuando se carga la página
    }    
});
