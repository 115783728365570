const PHONE_CONTAINER = '#branch_phones';
const PHONE_FORMAT = '.phone_format';

$( document ).on('turbolinks:load', function() {
  let $phone_container = $(PHONE_CONTAINER);
  let $phone_input = $(PHONE_FORMAT);

  $phone_input.each(function(){
    let rthis = this;

    $(this).on('blur', function() {
      this.value = rthis.updateFormat(this.value);
    });

    $(this).closest('form').on('submit', function(ev) {
      this.value = rthis.updateFormat(this.value);
    });

    this.updateFormat = function(phone){
      let result;
      if(!phone || phone === undefined)
        return phone
      result = phone.replace(/\s/g, '');
      return result;
    }
  })

  $phone_container.on("invalid", PHONE_FORMAT, function(){
    this.setCustomValidity("Número inválido - Ingresar al menos 9 dígitos");
  });

  $phone_container.on("change", PHONE_FORMAT, function(){
    this.setCustomValidity('');
    if((this.value !== undefined) && (this.value.replace(/\s/g, '').length < 9)){
      this.setCustomValidity("Número inválido - Ingresar al menos 9 dígitos");
    }
  });
});