$( document ).on('turbolinks:load', function() {
  if ($('.wizard-provider-form').length > 0) {
    const changeTextDni = ()=> {
 
      const replaceText = (inputElement, labelElement, message, labelText) => {
        if(message || labelText){
          inputElement.attr("placeholder",  "Ingresa tu" + " "  + labelText.toUpperCase());
          labelElement.text(message.split("(")[0]);
        }
      }

      const labelElement = $("label.col-form-label[for='provider_business_id']");
      const inputElement = $("#provider_business_id");
      const providerBusinessIdNode = $('#provider_business_id_type')
      const defaultValueSelected = providerBusinessIdNode.val();

      providerBusinessIdNode.select2();
      const defaultTextSelected = $('#provider_business_id_type').find(':selected').text();

      replaceText(inputElement, labelElement, defaultTextSelected, defaultValueSelected);
  
      $("#provider_business_id_type").on('select2:select', function (e) {
        const valueSelected = $("#provider_business_id_type").val();
        const selectedItemText = e.params.data.text;
        replaceText(inputElement, labelElement, selectedItemText,  valueSelected);
      });
      
    }

    const perCountryFields = () => {
      const toggleFieldsAndValidateForm = () => {
        const selectedCountry = $('#provider_address_country_id').val();
        
        // Código Postal - México
        if(selectedCountry == 142) {
          $('#postal_code_field').show();
          $('#provider_postal_code').prop('required', true).prop('disabled', false);
        } else {
          $('#postal_code_field').hide();
          $('#provider_postal_code').prop('required', false).prop('disabled', true).val('');
        }
        
        // Actividad Comercial - Colombia
        if(selectedCountry == 50) {
          $('#commercial_activity_field').show();
          $('#provider_commercial_activity').prop('required', true).prop('disabled', false);
          $('#is_self_retainer_field').show();
          $('#billing-rut-field').show();
        } else {
          $('#commercial_activity_field').hide();
          $('#provider_commercial_activity').prop('required', false).prop('disabled', true).val('');
          $('#is_self_retainer_field').hide();
          $('#billing-rut-field').hide();
        }
    
        validateForm();
      };
    
      const validateForm = () => {
        let isValid = true;
        $('.wizard-provider-form--form :input[required]:visible').each(function() {
          if (!$(this).val()) {
            isValid = false;
            return false;
          }
        });
    
        $('.wizard-provider-form--submit').prop('disabled', !isValid);
      };

      $('#provider_address_country_id').on('change', toggleFieldsAndValidateForm);
      toggleFieldsAndValidateForm();
      $('.wizard-provider-form--form').on('change', ':input[required]', validateForm);
    }

    perCountryFields()
    changeTextDni()
  }
});